exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bigass-index-js": () => import("./../../../src/pages/bigass/index.js" /* webpackChunkName: "component---src-pages-bigass-index-js" */),
  "component---src-pages-bigtiti-index-js": () => import("./../../../src/pages/bigtiti/index.js" /* webpackChunkName: "component---src-pages-bigtiti-index-js" */),
  "component---src-pages-mobilemenu-index-js": () => import("./../../../src/pages/mobilemenu/index.js" /* webpackChunkName: "component---src-pages-mobilemenu-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

